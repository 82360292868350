import { useStoreState } from "pullstate";
import dynamic from "next/dynamic";
import classnames from "classnames";
import Image from "next/image";

import Typography from "@/components/Typography";
import VolumeMixer from "@/components/Players/VolumeMixer";
import CloseIcon from "@/icons/close.svg";
import PodcastIcon from "@/icons/podcast-small.svg";
import VolumeIcon from "shared/icons/volume.svg";
import VolumeDownIcon from "shared/icons/volume-down.svg";
import {
  PlayerStore,
  psPauseAudio,
  radioSource,
  defaultRadioArtwork,
} from "@/store/PlayerStore";
import { PlayerStatusTypes } from "@/helpers/pageHelpers/Common/interface";
import { getImgixURL } from "@/helpers/getImgixURL";

import styles from "./DesktopPlayer.module.scss";
import { PlayerTabTypes } from "./DesktopPlayer.interface";
import PodcastPlayer from "./ContentPlayers/Podcast";
import RadioPlayer from "./ContentPlayers/Radio";

const PlayerTabs = [
  { name: "Podcast", type: PlayerTabTypes.Podcast, icon: <PodcastIcon /> },
];

const DesktopPlayer: React.FC = () => {
  const {
    hidePlayer,
    activePlayerTab,
    artwork,
    radioArtwork,
    scheduleArtworkUrl,
    previousData,
    isPlaying,
    isPodcastSource,
    isRadioSource,
    isPreviousPodcastAvailable,
  } = useStoreState(PlayerStore, (store) => ({
    ...store,
    isPlaying: store.playerStatus === PlayerStatusTypes.Playing,
    isRadioSource: store.currentSource === radioSource,
    isPodcastSource:
      !!store.currentSource?.length && store.currentSource !== radioSource,
    isPreviousPodcastAvailable: store.previousSource !== radioSource,
  }));

  const activePodcastArtwork = isRadioSource ? previousData?.artwork : artwork;
  const activeRadioArtwork =
    isPlaying && isRadioSource
      ? radioArtwork
      : scheduleArtworkUrl
        ? scheduleArtworkUrl
        : defaultRadioArtwork;

  const image = getImgixURL(
    activePlayerTab === PlayerTabTypes.Radio
      ? activeRadioArtwork
      : activePodcastArtwork,
  );

  const closePlayer = () => {
    PlayerStore.update(psPauseAudio);
    PlayerStore.update((store) => {
      store.hidePlayer = true;
    });
  };

  const setActiveTab = (type: PlayerTabTypes) => {
    PlayerStore.update((store) => {
      store.activePlayerTab = type;
    });
  };

  if (hidePlayer) return <></>;

  return (
    <div className={styles.wrapper}>
      <Image
        src={`${image}?blur=230`}
        objectFit="cover"
        layout="fill"
        alt=""
        className={styles.backgroundImage}
      />
      <div className={styles.content}>
        <div className={styles.mediaType}>
          {PlayerTabs.map(({ name, type, icon }) => (
            <button
              key={type}
              onClick={() => setActiveTab(type)}
              className={classnames(
                styles.button,
                activePlayerTab === type && styles.active,
                !isPreviousPodcastAvailable &&
                  !isPodcastSource &&
                  type === PlayerTabTypes.Podcast &&
                  styles.hide,
                `desktop-player-${name}-tab`,
              )}
            >
              {icon}
              <Typography component="span" variant="medium">
                {name}
              </Typography>
            </button>
          ))}
        </div>
        {activePlayerTab === PlayerTabTypes.Radio ? (
          <RadioPlayer />
        ) : (
          <PodcastPlayer />
        )}
        <div className={styles.volume}>
          <VolumeDownIcon className={styles.volumeIcon} />
          <VolumeMixer />
          <VolumeIcon className={classnames(styles.volumeIcon, styles.max)} />
        </div>
        <button
          className={classnames(styles.close, "close-desktop-player")}
          onClick={closePlayer}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

export default dynamic(() => Promise.resolve(DesktopPlayer), { ssr: false });
