import React from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import { useStoreState } from "pullstate";

import {
  accountPaths,
  authPaths,
  customRoutePaths,
  feedPaths,
  podcastPaths,
  routePath,
} from "shared/helpers/routePath";
import BottomNavPlayer from "shared/components/Players/BottomNavPlayer";
import { UIStore } from "@/store/UIStore";

import BottomNavigationAction from "./BottomNavigationAction/index";
import styles from "./BottomNavigation.module.scss";
import { PlayerStore } from "@/store/PlayerStore";

export const BottomNavigation: React.FC<{ isHidden?: boolean }> = ({
  isHidden,
}) => {
  const router = useRouter();
  const { userLoggedIn } = useStoreState(UIStore, (store) => store);
  const { hidePlayer } = useStoreState(PlayerStore, (store) => ({
    ...store,
  }));
  const isMobileApp = process.env.NEXT_PUBLIC_MOBILE_APP === "true";

  const isHomepage = feedPaths.includes(router.pathname);
  const isPodcasts = podcastPaths.includes(router.pathname);
  // const isRadio = radioPaths.includes(router.asPath);
  const isAuthPage = authPaths.includes(router.pathname);
  const isAccount = accountPaths.includes(router.pathname);
  const isSearch = customRoutePaths.includes(router.pathname);

  return (
    !isAuthPage && (
      <div
        className={classNames(
          styles.navigationWrapper,
          isHidden && styles.hide,
        )}
      >
        <nav
          className={classNames(
            styles.bottomNavigation,
            isMobileApp && styles.mobile,
          )}
        >
          <BottomNavigationAction
            href={routePath.FEED.href}
            icon={routePath.FEED.icon}
            label={routePath.FEED.label}
            isActive={isHomepage}
          />

          {/*<BottomNavigationAction*/}
          {/*  href={routePath.RADIO.href}*/}
          {/*  icon={routePath.RADIO.icon}*/}
          {/*  label={routePath.RADIO.label}*/}
          {/*  isActive={isRadio}*/}
          {/*/>*/}

          <BottomNavigationAction
            href={routePath.PODCASTS.href}
            icon={routePath.PODCASTS.icon}
            label={routePath.PODCASTS.label}
            isActive={isPodcasts}
          />

          <BottomNavigationAction
            href={routePath.SEARCH.href}
            icon={routePath.SEARCH.icon}
            label={routePath.SEARCH.label}
            isActive={isSearch}
          />

          <BottomNavigationAction
            href={userLoggedIn ? routePath.ACCOUNT.href : routePath.LOGIN.href}
            icon={routePath.ACCOUNT.icon}
            label={routePath.ACCOUNT.label}
            isActive={isAccount}
          />
        </nav>
        {/* Remove radio 29.11.2024 */}
        {!hidePlayer && <BottomNavPlayer />}
      </div>
    )
  );
};

export default BottomNavigation;
